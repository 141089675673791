export const TEXT = {
    GOOD: 'good',
    SUGGESTED: 'suggested',
    MANUAL: 'manual',
    INSURANCE_TYPE: 'insurance_type',
    INSURANCE_VALIDITY: 'insurance_validity',
    COMPREHENSIVE: 'comprehensive',
    THIRD_PARTY: 'third-party',
    ZERO_DEPRECIATION: 'zero-depreciation',
    EXPIRED: 'expired',
    CAR: 'car',
    VALUATION_USER_VALUES: 'valuationUserValues'
};

export const STORAGE_KEY = {
    POSTING_LOCATION: 'posting_location'
};

export const PROBABILITY_RANGE = {
    SIXTY: 60,
    EIGHTY: 80
};

export const EXPERIMENT_ID = {
    PRICE_RECOMMENDATION: 'PAN-43738',
    CAR_INSURANCE: 'PAN-44625',
    CATEGORY_SELECTION_POSTING: 'category_selection',
    CATEGORY_AI_GENERATED_ADS_VIDEO_PWA_DESKTOP: 'ai_generated_ads_video_pwa_desktop'
};

export const EXPERIMENT_AD_POSTING = {
    POSTING_FLOW: 'Posting_Revamp_PWA_New'
};

export const LACQUESIS_FEATURE_FLAG = {
    AUTOS_OPTION: {
        olxtr: 'ROAD-50085',
        olxin: 'ROAD-70737',
        olxid: 'ROAD-70739'
    }
};

export const TRACKING = {
    PPE_NUDGE_SHOWN: 'ppe_nudge_show',
    PPE_ADD_DETAILS: 'ppe_tap_add_details',
    PPE_POPUP_OPEN: 'ppe_details_popup_open',
    MY_ADS: 'my_ads',
    VIEW_ITEM: 'view_item',
    CURRENT_PAGE: 'current page',
    INSURANCE: 'insurance',
    PPE_ATTRIBUTE_COMPLETE: 'ppe_attribute_complete',
    PPE_CLOSE_CROSS: 'ppe_details_tap_close_popup',
    PPE_CLOSE_CANCEL: 'ppe_details_tap_cancel',
    PPE_DETAIL_SAVE: 'ppe_details_tap_save',
    PPE_ATTRIBUTE_SELECT: 'ppe_attribute_select',
    POSTING_ATTRIBUTE_COMPLETE: 'posting_attribute_complete',
    POSTING_ATTRIBUTE_SELECT: 'posting_attribute_select',
    LEGAL_TERMS_CONFIRM: 'legal_terms_confirm',
    LEGAL_TERMS_LINK_CLICK: 'legal_terms_linkclick',
    POSTING_ATTRIBUTE_SEARCH_CLICKED: 'posting_attribute_search_clicked'
};

export const TRACKING_PARAMS = {
    POSTING: 'posting',
    CAR_POSTING: 'car_posting',
    CHECK: 'check',
    UNCHECK: 'uncheck',
    LEGISLATION: 'legislation',
    LEGAL_TEXT: 'legal_text',
    CAR_DEALER_LIMITATION_CTC: 'car_dealer_limitation_c2c',
    REGULATION_TEXT: 'regulationText',
    EDITION: 'edition'
};

export const CATEGORY_MAPPING_TEXT = {
    CAR: '84',
    CAR_TEXT: 'Cars'
};

export const REMOVE_ICON_SIZE = {
    olx: 24,
    letgo: 12
};
export const CROSS_ICON_NAME = {
    olx: 'cross',
    letgo: 'crossFat'
};
export const CROSS_ICON_SIZE = {
    olx: 20,
    letgo: 14
};
export const COOKIES = {
    ONAP: 'onap',
    ONAP_DEV: 'onap_dev'
};
export const DEVICES = {
    MOBILE: 'mobile',
    DESKTOP: 'desktop'
};
export const AUTOS_URL = {
    olxtr: 'https://www.otoplus.com/araba-sat?redirection_source=pwa&redirection_medium=sell_button&redirection_campaign=c2b_pwa&redirection_session_id=',
    olxin: 'https://www.olxautos.in',
    olxid: 'https://www.olxautos.co.id/jual-mobil-bekas',
    utm: '?utm_source=classifieds&utm_medium=',
    session: '&utm_term=sell_button&redirection_session_id=',
    user_id: '&redirection_user_id='
};
export const AUTOS_IMAGE_ALT = 'AutosBanner';
export const DEFAULT_REMOVE_ICON_SIZE = 24;
export const LISTING_ITEMS_GAP_AD = 8;
export const LISTING_ITEMS_TOTAL_ADS = 6;
export const LISTING_MIDDLE_ADS_EXTRA_ITEMS = 10;
export const LISTING_MIDDLE_ADS_PATTERN_CHANGE_VALUE = 4;
export const LISTING_MIDDLE_ADS_PATTERN_CHANGE_ITEMS = 8;
export const LISTING_ITEMS_TOTAL_ADS_CATEGORY = 9;

export const DATA_AUT_IDS = {
    AUTOS_OPTION_CONTAINER: 'autosOptionContainer',
    AUTOS_OPTION_MODAL_CLOSE: 'autosOptionModalClose'
};

export const INDIA_AUTOS_LOCATIONS = [
    '4058659',
    '4058997',
    '4059014',
    '4059162',
    '4058526',
    '4058748',
    '4387779',
    '4059326',
    '4059280',
    '4059005',
    '4059026',
    '4058803',
    '4059164',
    '4058841',
    '4059123',
    '4157275',
    '4058677',
    '1000000000001575',
    '5313531',
    '4058651',
    '4058732',
    '4058889',
    '4058873'
];

export const titleTipsText = ['titleTipOne', 'titleTipTwo', 'titleTipThree'];

export const descriptionTipsText = ['descriptionTipOne', 'descriptionTipTwo', 'descriptionTipThree', 'descriptionTipFour', 'descriptionTipFive'];
