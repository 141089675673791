import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withConfig from 'HOCs/withConfig/withConfig';
import SearchAllCategories from './components/SearchAllCategories';
import BackToTop from './components/BackToTop';
import css from './SubHeader.desktop.scss';
import { BLACKBACKGROUND2 } from 'Constants/colors';

const SubHeader = ({ showBackToTop, preNotificationAvailable, config }) => {
    const isMxCl = config.get('adpvAuto', 'showMXDesign');
    const isOtoplus = config.get('theme', 'id') === 'otoplus';

    return (
        <React.Fragment>
            <div className={ classnames(css.headerSearchContainer,
                { [css.boxShadow]: isMxCl },
                preNotificationAvailable ? css.withPreNotification : css.withoutPreNotification
            ) }
            data-aut-id="subHeader"
            >

                {!isOtoplus
                && <React.Fragment>
                    { isMxCl
                        ? <div className={ classnames(css.sectionWrapper, css.emptySectionMxCl, { [css[BLACKBACKGROUND2]]: isOtoplus }) } />
                        : <div className={ classnames(css.sectionWrapper, css.emptySection) }>
                            <SearchAllCategories data-aut-id="heroSearchCategory" />
                        </div>
                    }
                </React.Fragment>
                }

            </div>
            { !!showBackToTop && <BackToTop /> }
        </React.Fragment>
    );
};

SubHeader.propTypes = {
    showBackToTop: PropTypes.bool,
    preNotificationAvailable: PropTypes.bool,
    config: PropTypes.shape({
        get: PropTypes.func.isRequired
    }).isRequired
};

SubHeader.defaultProps = {
    showBackToTop: false
};

const mapStateToProps = state => ({
    preNotificationAvailable: state.preNotification.preNotificationAvailable
});

export default compose(
    withConfig,
    connect(mapStateToProps)
)(SubHeader);
